<template>
	<div>
		<slot></slot>
	</div>
</template>

<script setup>

	useHead({
		bodyAttrs: {
			class: 'receipt-page'
		}
	})
</script>